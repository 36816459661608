// import logo_camtom_forAgents from "./images/camtom-forAgents.svg";
import React, { useEffect, useState } from "react";
import "../.././style.css";
import logo from "../../../imagesCommon/camtom-brokerage.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase.js";
import { useDispatch } from "react-redux";
import { loginAgent } from "../../../redux/reducers.js";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import AcceptCookies from "../../../cookies/AcceptCookies.js";
import logoAgencia from "../../../imagesCommon/logoAgencia.png";
import logoRuffo from "./iconos/logoRuffo.png";
import { useForm } from "react-hook-form";

import axios from "axios";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

const serverURL = process.env.REACT_APP_serverURL;

function LoginCustomsAgency() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log("tokenAgent", Cookies.get("tokenAgent"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    const dataToPost = {
      username: data.username,
      password: data.password,
    };

    try {
      // Firebase sign-in
      const agentCredential = await signInWithEmailAndPassword(
        auth,
        data.username,
        data.password
      );
  
      // Get the ID token from Firebase
      const idToken = await agentCredential.user.getIdToken();
  
      // Prepare the request body for your server
      const headers = {
        idToken: idToken,
      };
    
    // Send the ID token to your server for verification
      const agentResponse = await axios.post(
          `${serverURL}/api/login/customsAgencyEmployee`,
          {}, // This is the data object; include any payload you need to send here.
          { headers } // Pass the headers as the third argument
      );

      const { tempToken: token, email, customsAgencyId, customsAgencyEmployeeId } = agentResponse.data;

      Cookies.set("tokenAgent", token);
      Cookies.set("email", email);
      Cookies.set("customsAgencyId", customsAgencyId);
      Cookies.set("customsAgencyEmployeeId", customsAgencyEmployeeId);

      const agent = {
        token,
        email,
        customsAgencyId,
        customsAgencyEmployeeId, // customsAgencyEmployeeId
      };

      // console.log(agent);

      dispatch(loginAgent(agent));

      toast("¡Bienvenido a Camtom!", {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      setTimeout(() => {
        navigate(`/agent-dashboard`);
      }, 350);
    } catch (error) {
      
      const errorMessage =
        error.code === "auth/wrong-password"
          ? "Contraseña incorrecta"
          : error.code === "auth/user-not-found"
          ? "Usuario no encontrado"
          : error.code === "auth/too-many-requests"
          ? "Demasiados intentos fallidos. Tu cuenta está temporalmente inactiva. Cambia tu contraseña para recuperar el acceso o intenta más tarde."
          : error.response?.data || "Error de autenticación"
  
      toast(errorMessage, {
        icon: "❌",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      console.log(error);
    
    }
  };

  const tokenAgent = Cookies.get("tokenAgent");

  useEffect(() => {}, [tokenAgent, dispatch]);

  const logoSrc = window.location.href.includes("ruffo") ? logoRuffo : logo;

  return (
    <div className="login-container">
      <section className="section-logo">
        <img src={logoAgencia} alt="logoAgencia" />
      </section>

      <section className="section-login d-flex align-items-center p-5 padding-left-right-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 d-flex flex-column gap-2"
        >
          <a href="/" className="d-flex flex-column mb-5 text-color-camtom">
            <img className="logo-camtom" src={logoSrc} alt="Logo" />
            {/* <span className="letter-spacing-2 fs-4 ps-1">BROKERAGE</span> */}
          </a>

          <h1 className="my-5">Iniciar Sesión</h1>

          <div className="d-flex flex-column pb-4">
            <label htmlFor="username" className="pb-4 fw-normal fs-5">
              Correo electrónico:
            </label>
            <span className="input-border d-flex flex-row">
              <i className="bi bi-person fs-3 me-2"></i>
              <input
                type="text"
                id="username"
                className="input-login p-2 w-100"
                {...register("username", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </span>
            {errors.username && (
              <span className="span-error">{errors.username.message}</span>
            )}
          </div>

          <div className="d-flex flex-column pb-4">
            <label htmlFor="password" className="pb-4 fw-normal fs-5">
              Contraseña:
            </label>
            <span className="input-border d-flex flex-row">
              <i className="bi bi-lock fs-3 me-2"></i>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="input-login p-2 w-100"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
              {showPassword ? (
                <i
                  className="bi bi-eye-slash lh-3"
                  onClick={handleShowPassword}
                ></i>
              ) : (
                <i className="bi bi-eye lh-3" onClick={handleShowPassword}></i>
              )}
            </span>
            {errors.password && (
              <span className="span-error">{errors.password.message}</span>
            )}
          </div>

          <div className="d-flex flex-row align-items-baseline w-100">
            <button type="submit" className="btn-camtom p-3 w-25 me-5">
              Iniciar Sesión
            </button>
            <p className="text-center w-50">
              <a
                href="/login/forgot-password"
                style={{ color: "#004A7C" }}
              >
                ¿Olvidaste tu contraseña?
              </a>
            </p>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </form>
      </section>
    </div>
  );
}

export default LoginCustomsAgency;
