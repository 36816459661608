import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

import "./TariffPro.css";

import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import TariffPdf from "../../tariffCommon/TariffPdf.js";
import TariffResponse from "../../tariffCommon/TariffProResponse.js";

const TariffPro = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const token = useSelector((state) => state.user.token);

  // Consultar Fracción desde el hijo
  const handleSuccess = (result) => {
    setResponse(result); // Almacena la respuesta en el estado
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              <div className="upload-title">
              </div>

              <TariffPdf
                onSuccess={handleSuccess}
                token={token}
                setLoading={setLoading}
              />

              {response && (
                <h2 id="result" className="py-5">
                  ⬇️ {t("results")}
                </h2>
              )}

              <br />
              {loading && <p>{t("loading_p")}</p>}
              <br />
              <ul>
                <TariffResponse
                  response = {response}
                />
              </ul>
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffPro;
