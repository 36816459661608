import { useEffect, useState } from "react";
import getServices from "../../../services/getAgentServices";
import { store } from "../../../redux/store";

function HistoryView() {
  const service = new getServices();
  const state = store.getState();
  const tradingCompanyEmployeeId = state.user.tradingCompanyEmployeeId;
  
  // Initialize state variables
  const [data, setData] = useState();
  const [modelResult, setModelResult] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  const fetchDataHistory = async () => {
    const endpoint = `/classificationV2/tradingCompany/${tradingCompanyEmployeeId}`;
    try {
      await service.fetchAll(endpoint);
      const response = service.getResponse();
      
      const sortedData = response.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));  

      setData(sortedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchDataHistory();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      console.log('Data:', data); // Log the contents of data for debugging
      const dataToFilter = data.map((el) => {
        try {
          const parsedResult = JSON.parse(el.modelResult) || [];
          console.log('Parsed Model Result:', parsedResult); // Log the parsed model result
          return parsedResult; // Return the parsed result
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return []; // Return an empty array or handle the error as needed
        }
      });
      setModelResult(dataToFilter);
    }
  }, [data]);  

  if (loading) {
    return <p className="text-center my-5">Cargando...</p>; // Loading state UI
  }

  return (
    <section className="d-flex flex-column w-100 gap-3">
      <h1 className="my-5 ms-4">Historial de clasificaciones</h1>
      {/* Table thead */}
      <div className="w-75 d-flex flex-row gap-3 align-self-center">
        <span className="w-20 text-center fw-bold">
          <p className="mb-0 pb-0">Descripción</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p className="mb-0 pb-0">Tipo</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Fracción</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Input</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Fecha</p>
        </span>
      </div>
      {/* table tbody */}
      {data?.length > 0 ? (
        data.map((el, index) => (
          <div
            className="w-75 d-flex flex-column align-self-center"
            key={index}
          >
            <div className="w-100 d-flex flex-row gap-3">
              {el.predictionMode === "text" ? (
                <span className="w-20 pe-2">
                  <p className="mb-0 pb-0 text-start">{el.description}</p>
                </span>
              ) : (
                <span className="w-20 pe-2">
                  <p className="mb-0 pb-0 text-center">—</p>
                </span>
              )}
              <span className="w-20 pe-2">
                <p className="mb-0 pb-0 text-center">{el.predictionMode}</p>
              </span>
              <span className="w-20 pe-2 d-flex flex-row flex-wrap align-items-end h-100">
              {Array.isArray(modelResult[index]) ? (
                modelResult[index].map((item, i) => (
                  <p
                    className={`mb-0 pb-0 text-center px-2 border rounded-5 font-camtom-super-small d-flex align-items-center justify-content-center height-fit-content ${
                      item?.overall_confidence >= 0 && item?.overall_confidence <= 9
                        ? "bg-danger"
                        : item?.overall_confidence > 9 &&
                          item?.overall_confidence <= 49
                        ? "bg-warning-subtle"
                        : item?.overall_confidence > 49 &&
                          item?.overall_confidence <= 69
                        ? "bg-warning"
                        : item?.overall_confidence > 69 &&
                          item?.overall_confidence <= 91
                        ? "bg-success"
                        : item?.overall_confidence > 91 &&
                          item?.overall_confidence <= 100
                        ? "bg-info"
                        : ""
                    }`}
                    key={i}
                  >
                    {item.fraction.code}
                  </p>
                ))
                ): (
                  <p className="text-center">No model results available</p>
                )}
              </span>

              <span className="w-20 pe-2">
                {el.predictionMode !== "text" ? (
                  <p className="mb-0 pb-0 text-center">
                    <a
                      href={el.inputFilePath}
                      target="_blank" 
                      download={true}
                      className="text-decoration-none btn-camtom"
                    >
                      Ver {el.predictionMode}
                    </a>
                  </p>
                ) : (
                  <p className="mb-0 pb-0 text-center">{el.inputFilePath}</p>
                )}
              </span>
              <span className="w-20 pe-2">
                <p className="mb-0 pb-0 fw-bold text-center">
                  {new Date(el.timestamp).toLocaleDateString("es-Es")}
                </p>
              </span>
            </div>
            <hr />
          </div>
        ))
      ) : (
        <p className="text-center my-5 font-gray-light">
          No hay clasificaciones guardadas
        </p>
      )}
    </section>
  );
}

export default HistoryView;
