import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function TariffTxt({ onSuccess, token, setLoading }) {
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_serverURLtariff;

  const [blockBtn, setBlockBtn] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    // control,
  } = useForm();

  // Consultar Fracción
  const handleFraction = async () => {
    const url = `${BASE_URL}/api/v2/text-image`;
    setBlockBtn(!blockBtn);
    setLoading(true);

    const toastFracction = toast("Procesando...", {
      icon: "⏳ ",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
      duration: Infinity,
    });

    try {
      const values = getValues(); // Obtener todos los valores registrados en el formulario
      const prompt1 = values.prompt1;
      const prompt2 = values.prompt2;
      let body = {};

      if (prompt1 || prompt2) {
        body.userDescription = `${prompt1} ${prompt2}`;
        console.log(prompt1);
      }

      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const res = await response.json();
      // console.log(res, "respuesta");
      toast.dismiss(toast);

      if (res) {
        toast.success("Proceso completado", {
          id: toastFracction,
          duration: 5000,
        });
        onSuccess(res);
        setBlockBtn(false);
        setLoading(false);
      }
      // Pasar al siguiente step
    } catch (error) {
      console.log(error, "fff");

      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");

      toast.error(errorResponse, {
        icon: "❗❗",
        id: toastFracction,
        duration: 5000,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="description-instructions mt-5">
        <h5 className="my-3">1. ¿Qué es el producto?</h5>
        <input
          type="text"
          name="url_field"
          placeholder={t("txt_instruction_1_placeholder")}
          {...register(`prompt1`, {
            required: {
              value: true,
              message: t("required_message"),
            },
          })}
        />
      </div>
      {errors.prompt1 && <span className="span-error">{errors.message}</span>}

      <div className="description-instructions">
        <h5 className="my-3">2. ¿Qué hace el producto?</h5>
        <input
          type="text"
          name="url_field"
          {...register(`prompt2`, {
            required: {
              value: true,
              message: t("required_message"),
            },
          })}
          placeholder={t("txt_instruction_2_placeholder")}
        />
        {errors.prompt2 && <span className="span-error">{errors.message}</span>}
      </div>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
        <button
          className="btn-camtom align-self-center"
          onClick={() => handleFraction()}
          disabled={blockBtn}
        >
          {/* {t("btn_get_ranking")} */}
          Obtener clasificación
        </button>
      </div>
    </>
  );
}

export default TariffTxt;
