import React, { useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { storage } from "../../../../firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";

import image_upload from "../../Agents/iconos/image-upload-section.svg";
import check from "../../Agents/iconos/check.svg";
import exit from "../../Agents/iconos/exit.svg";

import "./TariffPro.css";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import { useNavigate } from "react-router-dom";
import TariffNoms from "../../tariffCommon/TariffNoms.js";
import TariffNearly from "../../tariffCommon/TariffNearly.js";
import TariffImg from "../../tariffCommon/TariffImg.js";
import TariffResponse from "../../tariffCommon/TariffProResponse.js";

const TariffPro = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const token = useSelector((state) => state.user.token);

  // Consultar Fracción desde el hijo
  const handleSuccess = (result) => {
    setResponse(result); // Almacena la respuesta en el estado
    // console.log(response);
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              <TariffImg
                onSuccess={handleSuccess}
                token={token}
                setLoading={setLoading}
              />

              <br />
              {response && (
                <h2 id="result" className="py-5">
                  ⬇️ {t("results")}
                </h2>
              )}

              {loading && <p>{t("loading_p")}</p>}
              <br />
              <ul>
                <ul>
                  <TariffResponse
                    response = {response}
                  />
                </ul>
              </ul>
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffPro;
