import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../../redux/reducers.js";

import logoCamtom from "../../images/logo_w.svg";
import logoRuffo from "./iconos/logoRuffo_white.png";

import "./Sidebar.css";

import { useSelector } from "react-redux";

import Language from "./Language";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

// Importa la acción que limpia el userId del estado de Redux
// Asegúrate de usar la acción correcta de tu aplicación
// import { logOut } from '../redux/actions';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = Cookies.get("tokenUser");

  const handleLogout = () => {
    dispatch(logOutUser());
    navigate("/login/trading-company");
  };

  const isActive = (path) => {
    return window.location.pathname === path ? "active" : "";
  };

  const logoSrc = window.location.href.includes("ruffo")
    ? logoRuffo
    : logoCamtom;

  // const handleIsToken = () => {
  //   setTimeout(() => {
  //     console.log("hello world");
  //   }, 1000);
  // };

  return (
    <div className="sidebar-user">
      <div className="logoContainer-user">
        <img className="logoSidebar-user" src={logoSrc} alt="Logo" />
      </div>
      <Language />
      <nav className="menu">
        <Link
          to="/user-dashboard"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list sidebar-home ${isActive(
              "/user-dashboard"
            )}`}
          >
            <i className="bi bi-house-door fs-2 me-3"></i>
            {t("sidebar_home")}
          </div>
          {/* HOME */}
        </Link>

        <Link
          to="/user-dashboard/tariff-pro-clasifications-database"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex ${isActive(
              "/user-dashboard/tariff-pro-clasifications-database"
            )}`}
          >
            <i className="bi bi-box-seam me-3 fs-4"></i>
            <p className="text break m-0">{t("sidebar_my_products")}</p>
          </div>
        </Link>

        <Link
          to="/user-dashboard/history"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex ${isActive(
              "/user-dashboard/history"
            )}`}
          >
            <i className="bi bi-collection me-3 fs-4"></i>
            <p className="text break m-0">Historial</p>
          </div>
        </Link>

        <Link
          to="/user-dashboard/tariff-pro"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex ${isActive(
              "/user-dashboard/tariff-pro"
            )}`}
          >
            <i className="bi bi-search me-3 fs-4"></i>
            <p className="text break m-0">{t("sidebar_clasifier")}</p>
          </div>
        </Link>

        {/* DIGITER */}

        <Link
          to="/user-dashboard/digiter"
          className="menu-item"
        >
          <div
            className={`sidebar-list ${isActive("/user-dashboard/digiter")}`}
          >
            <i className="bi bi-file-earmark-text me-3 fs-2"></i> Digiter
          </div>
        </Link> 

        <div className="divider"></div>

        <Link
          to="/user-dashboard/billing"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex ${isActive(
              "/user-dashboard/billing"
            )}`}
          >
            <i className="bi bi-cash fs-4 me-3"></i>
            <p className="m-0 letter-spacing text break">Billing</p>
          </div>
        </Link>

        <a href="https://camtomx.com" className="menu-item" target="blank">
          <div className="sidebar-list">
            <i className="bi bi-headset me-3 fs-2"></i>
            {t("sidebar_service")}
          </div>
        </a>
        <Link
          to="/user-dashboard/new-simulations"
          className="menu-item btn-new-simulations-user"
          // onClick={handleIsToken}
        >
          <div
            className={`d-flex align-items-center justify-content-center`}
            // inactive-option
          >
            <i className="bi bi-plus fs-2"></i>
            <p className="m-0">{t("sidebar_new_simulations")}</p>
            {/* <i className="bi bi-lock fs-5"></i> */}
          </div>
        </Link>
        <div className="sidebar-logout">
          <div
            className="d-flex flex-row align-items-center justify-content-center"
            onClick={handleLogout}
          >
            <i className="bi bi-box-arrow-right fs-2 me-3"></i>
            {t("sidebar_logout")}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
