import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";

import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import TariffImg from "../../tariffCommon/TariffImg.js";
import TariffResponse from "../../tariffCommon/TariffProResponse.js";

import "./TariffPro.css";

const TariffPro = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(); 
  const token = useSelector((state) => state.agent.token);

  // Consultar Fracción desde el hijo
  const handleSuccess = (result) => {
    setResponse(result); // Almacena la respuesta en el estado
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              <TariffImg
                onSuccess={handleSuccess}
                token={token}
                setLoading={setLoading}
              />

              <br />

              {response && (
                <h2 id="result" className="my-5">
                  ⬇️ {t("results")}
                </h2>
              )}
              <br />
              <ul>
                <ul>
                  <TariffResponse
                    response = {response}
                  />
                </ul>
              </ul>
            </div>
          </div>

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffPro;
