import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import "./TariffPro.css";

function TariffPdf({ onSuccess, token, setLoading }) {
  const BASE_URL = process.env.REACT_APP_serverURLtariff;

  const { t } = useTranslation();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFraction = async () => {
    const url = `${BASE_URL}/api/v2/pdf-file`;
    setLoading(true);
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    } else {
      alert(t("alert_empty"));
    }

    const options = {
      method: "POST",
      body: formData,
      "Content-Type": "multipart/form-data;",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await fetch(url, options);
      const res = await response.json();

      // console.log(response, url, res);
      onSuccess(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");

      toast.error(errorResponse, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // Quitar archivo
  const handleExitBtn = () => {
    setFile(null);
  };

  return (
    <div class="d-flex flex-column">
      <div className="upload-button">
        <h5 className="mt-4">1. {t("pdf_description_1")}</h5>
        <p className="mt-3">{t("pdf_description_1_p")}</p>

        <input
          type="file"
          id="pdfUpload"
          accept=".pdf"
          onChange={handleFileChange}
          style={{ display: "none" }}
          // disabled={loading}
        />
        <label htmlFor="pdfUpload" className="btn">
          <i className="bi bi-file-earmark-arrow-up-fill"></i>
          {t("pdf_placeholder")}
        </label>

        <div className={file ? "product-uploaded-dispatch" : ""}>
          {file && (
            <div>
              <i className="bi bi-check-circle-fill me-2 text-success"></i>

              {file?.name}
            </div>
          )}
          <span onClick={handleExitBtn}>
            {file && <i class="bi bi-x-circle-fill text-danger"></i>}
          </span>
        </div>
      </div>

      <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
        <button
          className="btn-camtom"
          onClick={handleFraction}
          // disabled={loading}
        >
          {t("btn_get_ranking")}
        </button>
      </div>
    </div>
  );
}

export default TariffPdf;
