import { useEffect, useState } from "react";
import logoCamtomDark from "../../../imagesCommon/logoCamtomDark.svg";
import "./DispatchRequest.css";
import { useTranslation } from "react-i18next";
import countriesCode from "../../resources/countriesCode.json";
import toast, { Toaster } from "react-hot-toast";
import citiesAndCp from "../../resources/unique_rows_city_and_postal_Code_df.json";
import TariffTxt from "../tariffCommon/TariffTxt";
import LoadingCamtom from "../User/quoter/LoadingCamtom";
import TariffImg from "../tariffCommon/TariffImg";
import TariffPdf from "../tariffCommon/TariffPdf";
import countriesAndCities from "../../resources/countries.json";
import { useFieldArray, useForm } from "react-hook-form";

function DispatchRequest() {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [sliderTypeOperation, setSliderTypeOperation] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [step, setStep] = useState(1);
  const [city, setCity] = useState(citiesAndCp);
  const [searchCityInput, setSearchCityInput] = useState("");
  const [showFiltered, setShowFiltered] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const [typeOperation, setTypeOperation] = useState("img");
  const [billResponse, setBillResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const [fractionResponse, setFractionResponse] = useState("");
  const [countryResponse, setCountryResponse] = useState("");
  const [merchandiseValueResponse, setMerchandiseValueResponse] = useState("");

  const BASE_URL = process.env.REACT_APP_serverURLtariff;
  const BASE_TESTING = process.env.REACT_APP_serverURL; // Para el endpoint de despacho
  const token = process.env.REACT_APP_dispatch_Token;

  const aduanasCamtom = [
    "Acapulco",
    "Agua Prieta",
    "Subte. López",
    "Cd. Del Carmen",
    "Cd. Juárez",
    "Coatzacoalcos",
    "Ensenada",
    "Guaymas",
    "La Paz",
    "Manzanillo",
    "Matamoros",
    "Mazatlán",
    "Mexicali",
    "México",
    "Naco",
    "Nogales",
    "Nuevo Laredo",
    "Ojinaga",
    "Puerto Palomas",
    "Piedras Negras",
    "Progreso",
    "Cd. Reynosa",
    "Salina Cruz",
    "SLRC",
    "Cd. Miguel A.",
    "Cd. Hidalgo",
    "Tampico",
    "Tecate",
    "Tijuana",
    "Tuxpan",
    "Veracruz",
    "Cd. Acuña",
    "Torreón",
    "AICM",
    "Guadalajara",
    "Sonoyta",
    "Lázaro C.",
    "Monterrey",
    "Cancún",
    "Querétaro",
    "Toluca",
    "Chihuahua",
    "Aguascalientes",
    "Puebla",
    "Colombia",
    "Altamira",
    "Cd. Camargo",
    "Dos Bocas",
    "Guanajuato",
    "AIFA",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    control,
  } = useForm();
  // Mirar UseEffect abajo para control de products
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  // Functions to Manage steps
  const handleNextStep = () => {
    if (step < 5) {
      setStep((prevStep) => prevStep + 1);
    }
    setProgress((prevProgress) => prevProgress + 25);
  };

  const handleReturn = () => {
    setStep((prevStep) => prevStep - 1);
    setProgress((prevProgress) => prevProgress - 25);
  };
  // Functions to Manage steps

  // Functions to extract data to bill
  const postBill = async () => {
    const endpoint = "/api/v2/file/pdf/reformhq/extract";
    // console.log(endpoint);
    const toastId = toast("Procesando...", {
      icon: "⏳ ",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
      duration: Infinity,
    });
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log(file);

      const Url = `${BASE_URL}${endpoint}`;
      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };

      const response = await fetch(Url, options);
      const res = await response.json();

      if (response.ok) {
        toast.success("Proceso completado", {
          id: toastId,
          duration: 5000,
        });
        setBillResponse(res);
        setCountryResponse(res.from.split(",")[2]?.trim()); // Ciudad origen
        setMerchandiseValueResponse(res.total_amount);
        setSearchCityInput(res.to.split(",")[0]); // Ciudad destino
        // Progreso
        setStep(2);
        setProgress((prevProgress) => prevProgress + 25);

        // console.warn(res, "res", billResponse, "bill");
        // console.warn(res.from.split(","));
        setLoading(false);
      } else {
        toast.error("Error al procesar la solicitud", {
          id: toastId,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Para vigilar los input
  const fraction = watch("fraction", fractionResponse);
  const country = watch("country", countryResponse);
  const merchandise = watch("merchandise", merchandiseValueResponse);
  const aduanaArribo = watch("aduanaArribo", "");
  const ciudadDestino = watch("ciudadDestino", searchCityInput);
  const codigoPostal = watch("codigoPostal", "");
  const fleteUSD = watch("fleteUSD", "");
  const hasPadron = watch("hasPadron", "");
  // console.log(countryResponse, country);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // setLoading(true);

    if (!fraction) {
      /*Si el usuario no ha puesto la fracción significa que 
       puso la factura(pdf) y hay que sacar la fracción aquí*/
      const endpointFraction = "/api/v2/pdf-file";
      try {
        const formData = new FormData();
        formData.append("file", file);
        console.log(file);

        const Url = `${BASE_URL}${endpointFraction}`;
        const options = {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " +
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cmFkaW5nQ29tcGFueUVtcGxveWVlSWQiOiI2NjZiZmZiNTRkOGI4Yzk1YzZlZjkwZWEiLCJpYXQiOjE3MjMxNzkyODl9.iSpCY-sYtPMIJ2qjGRaN5Y_j5AHZVx3dFMyLvBriH4M",
          },
          body: formData,
        };

        const response = await fetch(Url, options);
        const res = await response.json();
        setFractionResponse(res[0].fraction.code);
        console.log(res, "este obtiene lafracción");
      } catch (error) {
        console.error(error);
      }
    }

    const dataToPost = {
      user: {
        clientFirstName: data.nombre,
        clientLastName: data.apellido,
        user_email: data.correo,
      },
      country: countryResponse ? countryResponse : data.country, // Creo que va a desaparecer
      impo_or_expo: sliderTypeOperation ? "expo" : "impo",
      products:
        billResponse && billResponse.products_details
          ? billResponse.products_details.map((product) => ({
              n_items: product.quantity,
              valueMX: null,
              valueUSD: Number(product.unit_price.replace(/\D/g, "")),
              tariff_and_nico: fractionResponse
                ? fractionResponse
                : data.fraction, // Toma la fraccion de la factura o del input
              description: product.description,
            }))
          : data.products.map((product) => ({
              n_items: product.n_items,
              valueMX: null,
              valueUSD: Number(merchandise),
              tariff_and_nico: fraction || product.fraction,
              description: product.description,
            })),
      // customAgencyId: "64d1b7b9f6349b25c4998a9a",
      origin: {
        ciudad:
          billResponse && billResponse.from
            ? billResponse.from.split(",")[0]
            : "No agregado",
        pais: sliderTypeOperation ? "Mexico" : country,
        zipCode: !sliderTypeOperation ? data.codigoPostal : null,
        address: "",
        customs: data.aduanaDestino,
      },
      destination: {
        ciudad: sliderTypeOperation ? ciudadDestino : ciudadDestino,
        pais: sliderTypeOperation ? country : "Mexico",
        zipCode: sliderTypeOperation ? data.codigoPostal : null,
        address: "",
        customs: data.aduanaArribo,
      },
      tradingCompany: "64d1b7b9f6349b25c4998a9b",
      basicTradingCompanyInfo: {
        legalData: {
          businessName: data.empresa,
          taxID: "",
          legalRepresentativeName: "", //{ type: String, required: true },
          legalContactNumber: data.phone_code + data.telf,
        },
        commercialName: "", //{ type: String, required: true },
      },
      customsValue: Number(merchandise),
      userHasPadron: data.hasPadron,
      transportation: {
        internationalTransportation: {
          shippingCompany: "", //empresa encargada del envio
          price: data.fleteUSD,
          expirationDate: "", //cuando expira la cotizacion del transportista
        },
        domesticTransportation: {
          shippingCompany: "", //empresa encargada del envio
          price: "",
          expirationDate: "", //cuando expira la cotizacion del transportista
        },
        logisticsCoordinationFee: 100.0, // PONER INPUT?
      },
      status: "pending",
      // timestamps: true,
    };

    console.log(dataToPost, billResponse, "mirar aquiiiii", fraction);
    // Si la fracción existe ya se puede crear el despacho
    if (fraction || fractionResponse) {
      try {
        const endpoint = "/api/custom-clearance/create-dispatch-request/";
        const url = `${BASE_TESTING}${endpoint}`;

        const options = {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(dataToPost),
        };

        const response = await fetch(url, options);
        // const res = await response.json();
        console.log(response);

        if (response.ok) {
          setLoading(false);
          toast("Se ha creado una nueva solicitud de despacho", {
            icon: "🚀 ",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });

          const blob = await response.blob(); // Maneja la respuesta como un blob
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "archivo.pdf"; // Nombre del archivo
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Libera el objeto URL
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error();
        }
      } catch (err) {
        // ALERTAS DE ERRORES
        toast(err.message, {
          icon: "❗❗ Error al crear la solicitud",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });

        console.log(err);
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleExitBtnFile = () => {
    setFile(null);
  };

  const changeTypeOeration = (e) => {
    e.preventDefault();
    setSliderTypeOperation(!sliderTypeOperation);
  };
  // MOSTRAR EL INPUT DE FRACCIÓN
  const showInputFraction = () => {
    setShowInput(true);
  };
  // Busca la ciudad en el input
  const searchCity = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchCityInput(query);
    let filteredCity = [];

    if (!sliderTypeOperation) {
      filteredCity = citiesAndCp.filter(
        (el) =>
          el.D_mnpio.toLowerCase().includes(query) ||
          el.d_estado.toLowerCase().includes(query) ||
          el.d_codigo.toString().includes(query)
      );
    } else if (countriesAndCities[country]) {
      filteredCity = countriesAndCities[country].filter((el) =>
        el.toLowerCase().includes(query)
      );
    }
    setCity(filteredCity);
    setShowFiltered(true);
    // console.log(filteredCity);
  };

  // funcion para seleccionar la ciudad
  // const handleSelectCity = (city) => {
  //   setSearchCityInput(city);
  //   setShowFiltered(false);
  // };

  // Consultar Fracción desde el hijo
  const handleSuccess = (result) => {
    setFractionResponse(result[0].fraction.code); // Almacena la respuesta en el estado
  };
  // Añadir productos
  const addProduct = () => {
    append({ valueUSD: "", tariff_and_nico: "" });
  };
  // Para añadir productos
  useEffect(() => {
    // console.log(sliderTypeOperation);
    if ((fields.length === 0 && fraction) || fields.length === 0) {
      append({ valueUSD: "", tariff_and_nico: fraction }); // INICIALIZA UN PRIMER GRUPO
    }
  }, [append, fields, fraction]);

  useEffect(() => {
    // Estableciendo el valor del formulario con la respuesta de la api
    if (fractionResponse) {
      setValue("fraction", fractionResponse);
      setValue("country", countryResponse);
      setValue("merchandise", merchandiseValueResponse);
      // console.log(countryResponse);
    }
  }, [setValue, fractionResponse, countryResponse, merchandiseValueResponse]);

  useEffect(() => {
    // console.log(fractionResponse, "fraccion");
  }, [fractionResponse]);

  useEffect(() => {
    // console.log(progress);
  }, [progress]);

  useEffect(() => {
    // console.warn(city, searchCityInput);
  }, [city, searchCityInput]);

  useEffect(() => {
    if (
      (fractionResponse && step === 3) ||
      (billResponse.products_details && step === 3) ||
      (fraction && step === 3)
    ) {
      setStep(4);
      setProgress((prevProgress) => prevProgress + 25);

      console.log(billResponse);
    }
  }, [step, billResponse, fractionResponse, fraction]);

  useEffect(() => {
    // console.log(sliderTypeOperation);
  }, [sliderTypeOperation]);

  useEffect(() => {
    if (file) {
      postBill();
      // console.log(file);
    }
  }, [file]);

  return (
    <>
      {loading ? (
        <div className="w-100 border h-100vh d-flex align-items-center justify-content-center">
          <LoadingCamtom />
        </div>
      ) : (
        <div className="d-flex flex-column p-4 ps-5">
          <form
            className="d-flex flex-row gap-4 h-90vh"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* LEFT */}
            <div class="d-flex flex-column w-75 h-90vh">
              {/* POGRESS BAR */}
              {step > 1 && (
                <span class="d-flex align-items-center justify-content-between position-relative">
                  <div class="rounded-circle circle">1</div>
                  <div
                    class="progress-bar position-absolute z-index-1 rounded-pill"
                    style={{ width: `${progress}%` }}
                  ></div>

                  <div class="rounded-circle circle">2</div>

                  <div class="rounded-circle circle">3</div>

                  <div class="rounded-circle circle">4</div>

                  <div class="rounded-circle circle">5</div>
                </span>
              )}

              {/* STEP 1 (factura)*/}
              {step === 1 && (
                <>
                  {/* Logo section ↓ */}
                  <section className="d-flex mb-5">
                    <img src={logoCamtomDark} alt="Camtom" />
                  </section>
                  {/* Logo section ↑ */}

                  <h1 className="mb-3">
                    El despacho aduanal más rápido y transparente de México
                  </h1>
                  <p className="mb-3">
                    Cotiza en minutos contribuciones y pagos arancelarios,
                    transporte y honorarios del agente aduanal para tu mercancía
                    puesta en México.
                  </p>
                  {/* DEPLOY AREA */}
                  <div className="upload-area-dispatch align-self-center">
                    <input
                      type="file"
                      id="fileUpload"
                      accept=".pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="fileUpload"
                      className="dashed-border-dispatch"
                    >
                      <h3 className="dispatch-title">
                        Sube una factura de tu mercancía
                      </h3>
                      <p>
                        Generaremos una cotización completa de tu despacho
                        aduanal <i className="bi bi-stars"></i>
                      </p>
                      <small className="dispatch-small">
                        No te preocupes, podrás editar los datos capturados
                        antes de su confirmación.
                      </small>
                      <span class="d-flex flex-column">
                        <i className="bi bi-cloud-upload fs-1 text-icon-bill"></i>
                        <small className="dispatch-small">Formato PDF</small>
                      </span>
                    </label>
                  </div>
                  {/* Uploaded ? */}
                  <div className={file ? "product-uploaded-dispatch" : ""}>
                    <div>
                      {file && (
                        <i className="bi bi-check-circle-fill me-2 text-success"></i>
                      )}
                      {file && file?.name}
                    </div>
                    {file && (
                      <span onClick={handleExitBtnFile}>
                        <i class="bi bi-x-circle-fill text-danger"></i>
                      </span>
                    )}
                  </div>
                  {/* Uploaded ? */}

                  {/* DIVISION */}
                  <span className="d-flex flex-row justify-content-center m-5 align-items-center">
                    <i className="border border-black border-4 w-25"></i>
                    <p
                      className="p-2 text-center p-0 m-0 cursor-pointer"
                      onClick={handleNextStep}
                    >
                      O bien, llena la cotización manualmente
                    </p>
                    <i className="border border-black border-4 w-25"></i>
                  </span>
                </>
              )}

              {/* STEP 2 (info de operacion)*/}
              {step === 2 && (
                <section className="d-flex flex-column gap-3">
                  {/* RETURN BTN */}
                  <span className="d-flex align-items-baseline gap-3">
                    <i
                      className="bi bi-arrow-left-circle fs-2"
                      onClick={handleReturn}
                    ></i>
                    <h3 className="mt-5 fw-bold">
                      Información de la operación
                    </h3>
                  </span>

                  <h5 className="fw-lighter text-secondary">
                    Tipo de Operación
                  </h5>
                  {/* Slide btn */}
                  <div class="w-75 d-flex align-items-center mt-3">
                    <button
                      className="slider-btn-container position-relative fs-6"
                      onClick={changeTypeOeration}
                    >
                      <span
                        className={`btn-slider ${
                          sliderTypeOperation ? "slide-true" : "slide-false"
                        }`}
                      ></span>
                      <span className="d-flex flex-row align-items-center absolute-text">
                        <p
                          className={`my-0 w-50 ${
                            sliderTypeOperation
                              ? "active-btn-slider"
                              : "inactive-btn-slider"
                          }`}
                        >
                          {t("expo")}
                          <i className="bi bi-arrow-up-right ms-2"></i>
                        </p>
                        <p
                          className={`my-0 w-50 ${
                            sliderTypeOperation
                              ? "inactive-btn-slider"
                              : "active-btn-slider"
                          }`}
                        >
                          {t("impo")}
                          <i className="bi bi-arrow-down-left ms-2"></i>
                        </p>
                      </span>
                    </button>
                  </div>
                  {/* Slide btn */}

                  <div className="my-3">
                    <label
                      htmlFor="paisOrigen"
                      className="form-label fs-6 fw-lighter"
                    >
                      {
                        sliderTypeOperation
                          ? "País de Origen de la Mercancía" //t("country_of_origin")
                          : "País Destino de la Mercancía" //t("destination_country")
                      }
                    </label>
                    <select
                      className="form-control my-2 fs-6"
                      id="paisOrigen"
                      name="paisOrigen"
                      disabled
                    >
                      <option value="">México</option>
                    </select>
                  </div>
                  {/*  pais destino */}
                  <div className="mb-3">
                    <label
                      htmlFor="paisDestino"
                      className="form-label fs-6 fw-lighter"
                    >
                      {
                        sliderTypeOperation
                          ? "País Destino de la Mercancía" // t("destination_country")
                          : "País de Origen de la Mercancía" // t("country_of_origin")
                      }
                    </label>
                    <select
                      className="form-control my-2 fs-6"
                      id="paisDestino"
                      name="paisDestino"
                      {...register("country", {
                        required: {
                          value: true,
                          message: t("required_message"),
                        },
                      })}
                    >
                      <>
                        {country ? (
                          <option value={country}>
                            {country.split(",").pop().trim()}
                          </option>
                        ) : (
                          <option value="">Selecciona un país</option>
                        )}
                        <option value="">{t("select_country")}</option>
                        {countriesCode.countries?.map((el, index) => (
                          <option value={el?.name} key={index}>
                            {el?.name}
                          </option>
                        ))}
                      </>
                      {/* // )} */}
                    </select>
                    {errors.country && (
                      <span className="span-error">
                        {errors.country.message}
                      </span>
                    )}
                  </div>
                  {/*  pais destino */}

                  {/*  Aduana Mexicana de Arribo */}
                  <div className="mb-3">
                    {sliderTypeOperation ? (
                      <>
                        <label
                          htmlFor="aduanaSalida"
                          className="form-label fs-6 fw-lighter"
                        >
                          Aduana Mexicana de Salida
                        </label>
                        <input
                          type="text"
                          className="form-control my-2 fs-6"
                          id="aduanaSalida"
                          name="aduanaSalida"
                          placeholder=""
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="aduanaArribo"
                          className="form-label fs-6 fw-lighter"
                        >
                          Aduana Mexicana de Arribo
                        </label>
                        <select
                          className="form-control my-2 fs-6"
                          id="aduanaArribo"
                          name="aduanaArribo"
                          {...register("aduanaArribo", {
                            // required: {
                            //   value: true,
                            //   message: "Requiere aduana",
                            // },
                          })}
                        >
                          {aduanasCamtom.map((aduana) => (
                            <option value={aduana} key={aduana}>
                              {aduana}
                            </option>
                          ))}
                        </select>
                        {/* {errors.aduanaArribo && (
                          <span className="span-error">
                            {errors.aduanaArribo.message}
                          </span>
                        )} */}
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="ciudadDestino"
                      className="form-label fs-6 fw-lighter"
                    >
                      {
                        sliderTypeOperation
                          ? `Ciudad de Destino de la Mercancía en ${
                              country || "..."
                            }` // t("destination_country")
                          : `Ciudad de Destino de la Mercancía en México` // t("country_of_origin")
                      }
                    </label>
                    <input
                      type="text"
                      className="form-control my-2 fs-6"
                      id="ciudadDestino"
                      name="ciudadDestino"
                      placeholder="Aguascalientes"
                      value={searchCityInput}
                      onChange={searchCity}
                      // Capturar el valor manualmente
                    />
                    {/* {!sliderTypeOperation
                      ? showFiltered && (
                          <ul className="list-group">
                            {city.map((el, index) => (
                              <li
                                className="list-group-item cursor-pointer"
                                key={index}
                                onClick={() =>
                                  handleSelectCity(
                                    `${el.D_mnpio}, ${el.d_estado} - CP:${el.d_codigo}`
                                  )
                                }
                              >
                                {el.D_mnpio}, {el.d_estado} - CP: {el.d_codigo}
                              </li>
                            ))}
                          </ul>
                        )
                      : showFiltered && (
                          <ul className="list-group">
                            {city.map((el, index) => (
                              <li
                                className="list-group-item cursor-pointer"
                                key={index}
                                onClick={() => handleSelectCity(el)}
                              >
                                {el}
                              </li>
                            ))}
                          </ul>
                        )} */}

                    {/* Poner advertencia requerido */}
                  </div>
                  <div class="mb-3">
                    <label
                      htmlFor="codigoPostal"
                      className="form-label fs-6 fw-lighter"
                    >
                      Código Postal
                    </label>
                    {/* 
                      CODIGO POSTAL SIRVE PARA IMPO Y EXPO, 
                      xq si es impo el cp es para destino y 
                      si es expo el cp es para destino
                      */}
                    <input
                      type="text"
                      className="form-control my-2 fs-6"
                      id="codigoPostal"
                      name="codigoPostal"
                      placeholder="Código Postal"
                      {...register("codigoPostal")}
                    />
                  </div>
                  {country && ciudadDestino && (
                    <button
                      className="btn-camtom align-self-end"
                      onClick={handleNextStep}
                    >
                      Continuar
                    </button>
                  )}
                </section>
              )}

              {/* STEP 3 (Fracción arancelaria) */}
              {step === 3 && (
                <section className="d-flex flex-column gap-3">
                  {/* RETURN BTN */}
                  <span className="d-flex align-items-baseline gap-3">
                    <i
                      className="bi bi-arrow-left-circle fs-2"
                      onClick={handleReturn}
                    ></i>
                    <h3 className="mt-5 fw-bold">Fracción arancelaria</h3>
                  </span>
                  <h5>
                    Obtén la fracción arancelaria de tus mercancías con
                    Inteligencia Artificial, desde:
                  </h5>
                  {/* TIPO DE OPERACION SELECCION */}
                  <section className="d-flex w-100 justify-content-evenly">
                    <button
                      className={`menu-item-tariff ${
                        typeOperation === "img" ? "tariff-active" : null
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setTypeOperation("img");
                      }}
                    >
                      <i className="bi bi-card-image me-2"></i>
                      <span>Una imagen</span>
                    </button>
                    <button
                      className={`menu-item-tariff ${
                        typeOperation === "txt" ? "tariff-active" : null
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setTypeOperation("txt");
                      }}
                    >
                      <i className="bi bi-card-text me-2"></i>
                      <span>Descripción por texto</span>
                    </button>
                    <button
                      className={`menu-item-tariff ${
                        typeOperation === "pdf" ? "tariff-active" : null
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setTypeOperation("pdf");
                      }}
                    >
                      <i className="bi bi-file-pdf me-2"></i>
                      <span>Documento PDF</span>
                    </button>
                  </section>
                  {typeOperation === "img" ? (
                    <TariffImg
                      onSuccess={handleSuccess}
                      token={token}
                      setLoading={setLoading}
                    />
                  ) : typeOperation === "txt" ? (
                    <TariffTxt
                      onSuccess={handleSuccess}
                      token={token}
                      setLoading={setLoading}
                    />
                  ) : typeOperation === "pdf" ? (
                    <TariffPdf
                      onSuccess={handleSuccess}
                      token={token}
                      setLoading={setLoading}
                    />
                  ) : null}

                  {/* DIVISION */}
                  <span className="d-flex flex-row justify-content-center m-5 align-items-center">
                    <i className="border border-black border-4 w-25"></i>
                    <p
                      className="p-2 text-center p-0 m-0 cursor-pointer"
                      onClick={showInputFraction}
                    >
                      O bien, ingresa la fracción arancelaria manualmente
                    </p>
                    <i className="border border-black border-4 w-25"></i>
                  </span>
                  {showInput && (
                    <>
                      <label for="fraction">
                        <h5>
                          Si ya la conoces, ingresa la fracción arancelaria
                        </h5>
                      </label>
                      <input
                        type="number"
                        id="fraction"
                        className="form-control"
                        minLength="8"
                        maxLength="8"
                        pattern="\d{8}"
                        title="Debe ingresar exactamente 8 dígitos"
                        {...register(`fraction`, {
                          minLength: {
                            value: 8,
                            message: "La fraccion debe tener mínimo 8 dígitos",
                          },
                          maxLength: {
                            value: 8,
                            message:
                              "La fraccion no debe tener más de 8 dígitos",
                          },
                          pattern: {
                            value: /^[0-9]/,
                            message: "La fraccion debe ser de 8 dígitos",
                          },
                          required: {
                            value: true,
                            message: t("required_message"),
                          },
                        })}
                      />
                      {errors.fraction && (
                        <span className="span-error">
                          {errors.fraction.message}
                        </span>
                      )}
                      {/* ASEGURANDO QUE LA FRACCIÓN TENGA 8 DÍGITOS */}
                      {fraction?.length === 8 && (
                        <button
                          className="btn-camtom align-self-end"
                          onClick={handleNextStep}
                        >
                          Continuar
                        </button>
                      )}
                    </>
                  )}
                </section>
              )}

              {/* STEP 4 (valor en aduana) */}
              {step === 4 && (
                <section className="d-flex flex-column gap-3">
                  {/* RETURN BTN */}
                  <span className="d-flex align-items-baseline gap-3">
                    <i
                      className="bi bi-arrow-left-circle fs-2"
                      onClick={handleReturn}
                    ></i>
                    <h3 className="mt-5 fw-bold">Valor en Aduana</h3>
                  </span>
                  {/*  */}
                  <div className="mb-3">
                    <label
                      htmlFor="merchandise"
                      className="form-label fw-lighter"
                    >
                      Valor de la mercancía ($US dollar)
                    </label>
                    <input
                      type="number"
                      id="merchandise"
                      className="form-control"
                      value={
                        merchandiseValueResponse ? merchandiseValueResponse : ""
                      }
                      {...register("merchandise", {
                        required: {
                          value: true,
                          message: "Valor requerido",
                        },
                        onChange: (e) => {
                          setValue("merchandise", e.target.value); // Actualizar el valor manualmente cuando el usuario lo edite
                          setMerchandiseValueResponse(e.target.value); // Actualizar el estado de merchandiseValueResponse también
                        },
                      })}
                    />
                    {errors.merchandise && (
                      <span className="span-error">
                        {errors.merchandise.message}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fleteUSD" className="form-label fw-lighter">
                      Valor del flete ($US dollar)
                    </label>
                    <input
                      type="number"
                      id="fleteUSD"
                      className="form-control"
                      {...register("fleteUSD", {
                        required: {
                          value: true,
                          message: "Valor requerido",
                        },
                      })}
                    />
                    {errors.fleteUSD && (
                      <span className="span-error">
                        {errors.fleteUSD.message}
                      </span>
                    )}
                  </div>

                  {/* LEGAL */}
                  <h3 className="mt-5 fw-bold">Legal</h3>
                  <h3>¿Cuentas con padrón de importadores y exportadores?</h3>

                  <div className="d-flex flex-column gap-3" role="group">
                    <input
                      type="radio"
                      className="btn-check"
                      id="yes"
                      value="yes"
                      {...register("hasPadron", { required: true })}
                      autoComplete="off"
                      defaultChecked
                    />
                    <label
                      className="d-flex fw-bold padron-option align-items-center cursor-pointer"
                      htmlFor="yes"
                    >
                      Sí, cuento con padrón inscrito
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="arrange"
                      value="arrange"
                      {...register("hasPadron", { required: true })}
                      autoComplete="off"
                    />
                    <label
                      className="d-flex fw-bold padron-option justify-content-between align-items-center cursor-pointer"
                      htmlFor="arrange"
                    >
                      <p class="m-0 p-0">
                        No tengo, y quiero incluir el trámite a mi cotización
                      </p>
                      <div class="d w-10 d-flex align-items-center justify-content-end">
                        <div class="blur p-2 w-75"></div>
                        <div className="">$</div>
                      </div>
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="no"
                      value="no"
                      {...register("hasPadron", { required: true })}
                      autoComplete="off"
                    />
                    <label
                      className="d-flex fw-bold padron-option justify-content-between align-items-center cursor-pointer"
                      htmlFor="no"
                    >
                      <p class="m-0 p-0">
                        No tengo, pero quiero usar el padrón de tu
                        comercializadora
                      </p>
                      <div class="d w-10 d-flex align-items-center justify-content-end">
                        <div class="blur p-2 w-75"></div>
                        <div className="">$</div>
                      </div>
                    </label>
                  </div>

                  {/* PRODUCTOS */}
                  <h3 className="mt-5 fw-bold">Productos</h3>
                  <h3>Añade información sobre tu producto</h3>
                  {fields.map((item, index) => (
                    <div key={item.id} className="mb-4">
                      <span className="d-flex flex-row gap-2">
                        <small className="strong font-gray-light">
                          {`Producto ${index + 1}`}
                        </small>
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="btn btn-danger btn-sm ms-auto"
                        >
                          Eliminar
                        </button>
                      </span>
                      <hr className="hr" />
                      <div className="mb-3 d-flex flex-column">
                        <span className="w-100 d-flex flex-column gap-2 justify-content-between mb-3">
                          <label
                            htmlFor={`products[${index}].description`}
                            className="form-label"
                          >
                            Descripción
                          </label>
                          <input
                            type="string"
                            id={`products[${index}].description`}
                            className="form-control"
                            {...register(`products[${index}].description`, {
                              required: {
                                value: true,
                                message: t("required_message"),
                              },
                            })}
                          />
                          {errors.products &&
                            errors.products[index].description && (
                              <span className="span-error">
                                {errors.products[index].description.message}
                              </span>
                            )}
                        </span>
                        <span className="w-100 d-flex flex-column gap-2 justify-content-between mb-3">
                          <label
                            htmlFor={`products[${index}].n_items`}
                            className="form-label"
                          >
                            Número de ítems
                          </label>
                          <input
                            type="string"
                            id={`products[${index}].n_items`}
                            className="form-control"
                            {...register(`products[${index}].n_items`, {
                              required: {
                                value: true,
                                message: t("required_message"),
                              },
                            })}
                          />
                          {errors.products &&
                            errors.products[index].n_items && (
                              <span className="span-error">
                                {errors.products[index].n_items.message}
                              </span>
                            )}
                        </span>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addProduct}
                    className="btn-camtom-light align-self-center"
                  >
                    Añadir producto
                  </button>

                  {merchandise && fleteUSD && (
                    <button
                      className="btn-camtom align-self-end"
                      onClick={handleNextStep}
                    >
                      Continuar
                    </button>
                  )}
                </section>
              )}

              {/* STEP 5 (legal)*/}
              {step === 5 && (
                <section className="d-flex flex-column justify-content-between h-90vh">
                  <div className="d-flex flex-column gap-3">
                    <span className="d-flex align-items-baseline gap-3">
                      <i
                        className="bi bi-arrow-left-circle fs-2"
                        onClick={handleReturn}
                      ></i>
                      <h3 className="mt-5 fw-bold">Datos de contacto</h3>
                    </span>
                    <span class="d-flex flex-column gap-3">
                      {/* Nombre apellido */}
                      <div class="d-flex flex-row gap-3">
                        <div className="mb-3 w-50">
                          <label
                            htmlFor="nombre"
                            className="form-label fw-lighter"
                          >
                            Nombre
                          </label>
                          <input
                            type="string"
                            id="nombre"
                            className="form-control"
                            {...register("nombre", {
                              required: {
                                value: true,
                                message: "Nombre requerido",
                              },
                            })}
                          />
                          {errors.nombre && (
                            <span className="span-error">
                              {errors.nombre.message}
                            </span>
                          )}
                        </div>
                        <div className="mb-3 w-50">
                          <label
                            htmlFor="apellido"
                            className="form-label fw-lighter"
                          >
                            Apellido
                          </label>
                          <input
                            type="string"
                            id="apellido"
                            className="form-control"
                            {...register("apellido", {
                              required: {
                                value: true,
                                message: "Apellido requerido",
                              },
                            })}
                          />
                          {errors.apellido && (
                            <span className="span-error">
                              {errors.apellido.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Empresa Correo */}
                      <div class="d-flex flex-row gap-3">
                        <div className="mb-3 w-50">
                          <label
                            htmlFor="empresa"
                            className="form-label fw-lighter"
                          >
                            Empresa
                          </label>
                          <input
                            type="string"
                            id="empresa"
                            className="form-control"
                            {...register("empresa", {
                              required: {
                                value: true,
                                message: "Empresa requerido",
                              },
                            })}
                          />
                          {errors.empresa && (
                            <span className="span-error">
                              {errors.empresa.message}
                            </span>
                          )}
                        </div>
                        <div className="mb-3 w-50">
                          <label
                            htmlFor="correo"
                            className="form-label fw-lighter"
                          >
                            Correo
                          </label>
                          <input
                            type="email"
                            id="correo"
                            className="form-control"
                            {...register("correo", {
                              required: {
                                value: true,
                                message: "Correo requerido",
                              },
                            })}
                          />
                          {errors.correo && (
                            <span className="span-error">
                              {errors.correo.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Telf */}
                      <label htmlFor="telf" className="form-label fw-lighter">
                        Número celular
                      </label>
                      <div class="d-flex flex-row gap-1 input-phone w-50 align-items-center">
                        <select
                          className="select-invisible w-25"
                          name="phone_code"
                          {...register("phone_code", {})}
                        >
                          {countriesCode.countries?.map((el, index) => (
                            <option
                              value={el?.phone_code}
                              key={`${el.phone_code}-${index}`}
                            >
                              <p>{el?.flag_icon} </p> <p>{el.phone_code}</p>
                            </option>
                          ))}
                        </select>
                        |
                        <input
                          type="string"
                          id="telf"
                          className="select-invisible"
                          placeholder="6652287"
                          {...register("telf", {
                            required: {
                              value: true,
                              message: "Número de teléfono requerido",
                            },
                          })}
                        />
                      </div>
                      {errors.telf && (
                        <span className="span-error">
                          {errors.telf.message}
                        </span>
                      )}
                    </span>
                  </div>
                  <button className="btn-camtom align-self-end">
                    Crear cotización
                  </button>
                </section>
              )}
            </div>

            {/* RIGHT (datos de contacto)*/}
            <div class="d-flex flex-column gap-3 w-25 resume">
              {Object.keys(billResponse).length <= 0 ? (
                sliderTypeOperation ? (
                  <h4>
                    Exportación <i className="bi bi-arrow-up-right ms-2"></i>
                  </h4>
                ) : (
                  <h4>
                    Importación <i className="bi bi-arrow-down-left ms-2"></i>
                  </h4>
                )
              ) : billResponse.to?.includes("Mexico") ? (
                <h4>
                  Importación <i className="bi bi-arrow-down-left ms-2"></i>
                </h4>
              ) : (
                <h4>
                  Exportación <i className="bi bi-arrow-up-right ms-2"></i>
                </h4>
              )}
              <small>Origen</small>
              <p>
                <b>
                  {country && !sliderTypeOperation
                    ? country
                    : countriesCode.countries.find((el) => el.code === country)
                        ?.name || "México"}
                </b>
              </p>
              <small>Destino</small>
              <p>
                <b>
                  {billResponse.to ||
                    (country && sliderTypeOperation ? country : "México")}
                </b>
              </p>
              <small>Fracción arancelaria</small>
              {fraction || billResponse.products_details ? (
                <div class="blur p-3 w-50"></div>
              ) : (
                <p>
                  <b>No agregado</b>
                </p>
              )}
              {/* <p>
                <b>{fraction || "No agregado"}</b> (Quitar esto)
              </p> */}
              <small>Aduana Mexicana</small>
              <p>
                <b>{aduanaArribo || "No agregado"}</b>
              </p>
              <small>Ciudad de Destino</small>
              <p>
                <b>{ciudadDestino || "No agregado"}</b>
              </p>

              <small>Código Postal</small>
              <p>
                <b>{codigoPostal || "No agregado"}</b>
              </p>
              {/*  */}
              <small>Padrón</small>
              <p>
                <b>{hasPadron || "No especificado"}</b>
              </p>
              {/*  */}
              <span className="w-100 d-flex gap-2">
                <small className="w-50">Valor en Aduana</small>
                <small className="w-50">Flete</small>
              </span>
              <span className="w-100 d-flex gap-2">
                <p className="w-50">
                  <b>$ {merchandise || "000"}</b>
                </p>

                <p className="w-50">
                  <b>$ {fleteUSD || "000"}</b>
                </p>
              </span>
            </div>
            <Toaster />
          </form>
        </div>
      )}
    </>
  );
}

export default DispatchRequest;