import { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
// import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function TariffPro() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isActive = (path) => {
    console.log(window.location.pathname);
    return window.location.pathname === path ? "tariff-active" : "";
  };

  useEffect(() => {
    // Redirige a la subruta img
    const currentPath = window.location.pathname;
    const targetPath = "/agent-dashboard/tariff-pro/tariff-pro-text";
    if (currentPath === "/agent-dashboard/tariff-pro") {
      navigate(targetPath);
    }
  }, [navigate]);
  return (
    <div className="d-flex flex-column p-5">
      <h1>{t("tariff_classifier")}</h1>

      <div class="d-flex flew-row align-items-center justify-content-center w-100 gap-2 my-5">
        <Link
          to="tariff-pro-text"
          className={`menu-item-tariff ${isActive(
            "/agent-dashboard/tariff-pro/tariff-pro-text"
          )}`}
        >
          <div>
            {/* <i className="bi bi-justify-left me-2 fs-2"></i> */}
            {t("sidebar_txt")}
          </div>
        </Link>

        <Link
          to="tariff-pro-img"
          className={`menu-item-tariff ${isActive(
            "/agent-dashboard/tariff-pro/tariff-pro-img"
          )}`}
        >
          <div>
            {/* <i className="bi bi-file-image me-2 fs-2"></i> */}
            {t("sidebar_img")}
          </div>
        </Link>

        <Link
          to="tariff-pro-pdf"
          className={`menu-item-tariff ${isActive(
            "/agent-dashboard/tariff-pro/tariff-pro-pdf"
          )}`}
        >
          <div>
            {/* <i className="bi bi-filetype-pdf me-2 fs-2"></i> */}
            {t("sidebar_pdf")}
          </div>
        </Link>

        <Link
          to="tariff-pro-xlsx"
          className={`menu-item-tariff ${isActive(
            "/agent-dashboard/tariff-pro/tariff-pro-xlsx"
          )}`}
        >
          <div>
            {/* <i className="bi bi-filetype-xlsx me-2 fs-2"></i> */}
            {t("sidebar_xlsx")}
          </div>
        </Link>
      </div>

      {/* Subrutas */}
      <Outlet />
    </div>
  );
}

export default TariffPro;
