import React from "react";
import { useNavigate } from "react-router-dom";


export default function Redirect() {

const navigate = useNavigate();

const handleRedirectLoginTradingCompany = () => {
  navigate("/user-dashboard");
};

const handleRedirectLoginCustomsAgency = () => {
  navigate("/agent-dashboard");
};

  return (
      
    <div className="html d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <button
          type="button"
          onClick={handleRedirectLoginTradingCompany}
          className="btn-camtom-light mb-3"
        >
          Portal de Empresa
        </button>

        <br />
        <button
          type="button"
          onClick={handleRedirectLoginCustomsAgency}
          className="btn-camtom"
        >
          Portal de Agencia Aduanal
        </button>
      </div>
    </div>

    )
}
