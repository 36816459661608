// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Dashboard from "./Dashboard";
// import FormSinglePage from "./FormSinglePage";
// import Overlay from "./Overlay";
// import QuoteForm from "./MVP/User/QuoteForm";
// import DashboardMVP from "./MVP/User/DashboardMVP";
// import ArticleCreator from "./Blog/ArticleCreator";
// import ArticleTemplate from "./Blog/ArticleTemplate";
// import ListArticle from "./Blog/ListArticle";
// import AgentLanding from "./AgentLanding";
// import AgentPricing from "./AgentPricing";

// import Sitemap from "./Sitemap";
// import CommonElements from "./style/CommonElements";
// import FormSinglePageC from "./FormSinglePageC";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import "./App.css";

import Success from "./Success";
import AgentDashboard from "./MVP/Agents/DashboardAgent";
import UserDashboard from "./MVP/User/DashboardMVP.js";

import LoginCustomsAgency from "../components/MVP/Agents/LoginCustomsAgency.js";
import LoginTradingCompany from "./MVP/User/LoginTradingCompany.js";
import ForgotPassword from "./MVP/ForgotPassword.js";
import DispatchRequest from "./MVP/dispatch/DispatchRequest.js";

import Redirect from "./RedirectLogins.js";
import RegisterCompanyAgency from "./MVP/RegisterCompanyAgency.js";

import Footer from "./Footer";

import { Provider, useDispatch, useSelector } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../redux/store.js";

import ErrorBoundary from "./ErrorBoundary";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { logOutAgent, logOutUser } from "../redux/reducers.js";

const AppRoutes = () => {
  const tokenUser = useSelector((state) => state.user.token);
  const tokenAgent = useSelector((state) => state.agent.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkTokenUser = Cookies.get("tokenUser");
    const checkTokenAgent = Cookies.get("tokenAgent");

    if (!checkTokenUser) {
      dispatch(logOutUser());
    }
    if (!checkTokenAgent) {
      dispatch(logOutAgent());
    }
  }, [dispatch]);

  const PrivateRouteUser = ({ children }) => {
    return tokenUser ? (
      children
    ) : (
      <Navigate to="/login/trading-company" replace />
    );
  };

  const PrivateRouteAgent = ({ children }) => {
    return tokenAgent ? (
      children
    ) : (
      <Navigate to="/login/customs-agency" replace />
    );
  };

  return (
    <Router>
      <ErrorBoundary>
        <div className="App">
          <Routes>
            <Route path="/success/:messageType" element={<Success />} />
            <Route
              path="/login/forgot-password"
              element={<ForgotPassword />}
            />
            <Route
              path="/login/customs-agency"
              element={<LoginCustomsAgency />}
            />
            <Route path="/" element={<Redirect />} />
            <Route
              path="/login/trading-company"
              element={<LoginTradingCompany />}
            />
            <Route
              path="/register-camtom-piolin"
              element={<RegisterCompanyAgency />}
            />
            <Route path="/dispatch-request" element={<DispatchRequest />} />
            <Route
              path="/agent-dashboard/*"
              element={
                <PrivateRouteAgent>
                  <AgentDashboard />
                </PrivateRouteAgent>
              }
            />
            <Route
              path="/user-dashboard/*"
              element={
                <PrivateRouteUser>
                  <UserDashboard />
                </PrivateRouteUser>
              }
            />
          </Routes>
        </div>
      </ErrorBoundary>
    </Router>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
};

export default App;
